<template>
  <v-content>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-content>
</template>

<script>
export default {
  name: "ViewWeb",
  components:{
    BackToTop: () => import('vue-backtotop')
  },
  methods: {
    scrollToTop() {
      setInterval(()=>{
        window.scrollTo(0, 0);
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.view
  position: fixed
  right: 0
  margin: 20px
  top: 75%

@media screen and (max-width: 959px)
  .v-main
    padding: 0px !important
</style>
